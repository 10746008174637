import React from 'react';
import { Modal, Button} from 'semantic-ui-react';

function Avertissement(props) {

    const close = () => {
        props.changeAll(false);
    }

    const ok = () => {
        props.changeAll(true);
    }

    if(props.open === true){
        return (
            <Modal
                open={props.open}
                closeOnEscape={false}
            >
            <Modal.Header>Après la suppression du contenu de toute la page il est impossible de revenir en arrière.</Modal.Header>
            <Modal.Content>
                <p>Etes vous sur de vouloir supprimer le contenu de toute la page ?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} negative>
                Annuler
                </Button>
                <Button
                onClick={ok}
                positive
                labelPosition='right'
                icon='checkmark'
                content='Valider'
                />
            </Modal.Actions>
            </Modal>
        )
    }else{
        return null;
    }
    
}

export default Avertissement;