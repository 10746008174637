import React, { Fragment, Component } from 'react';
import { Menu, Icon, Label } from 'semantic-ui-react';

class MenuCanvas extends Component {

    constructor(props){
        super(props);
        this.state = {
            color: "black"
        }
        this.handleZoomPlus = this.handleZoomPlus.bind(this);
        this.handleZoomMinus = this.handleZoomMinus.bind(this);
        this.handleClickUndo = this.handleClickUndo.bind(this);
        this.handleClickGrag = this.handleClickGrag.bind(this);
    }

    handleZoomPlus = () => {
        if(this.props.zoom > 0.6){
            this.props.handleZoomPlus();
        }
    }

    handleZoomMinus = () => {
        if(this.props.zoom < 1.9){
            this.props.handleZoomMinus();
        }
    }

    handleClickUndo = () => {
        this.props.handleClickUndo();
    }

    handleClickGrag = () => {
        if(this.props.grab !== true){
            this.setState({
                color: "orange"
            })
        }else{
            this.setState({
                color: "black"
            })
        }
        this.props.handleClickGrag();
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.grab !== this.props.grab){
            if(this.props.grab === true){
                this.setState({
                    color: "orange"
                })
            }else{
                this.setState({
                    color: "black"
                })
            }
        }
    }

    render(){
        return (
        <Fragment>
            <Menu inverted icon vertical style={{position: "absolute", top: "14em",right: "5px" }}>
                    <Menu.Item
                        name='undo alternate'
                        onClick={this.handleClickUndo}
                        >
                        <Icon name='undo alternate' />
                    </Menu.Item>
            </Menu>

            {
                this.state.color === "black" ? (
                    <Menu inverted icon vertical style={{position: "absolute", top: "17em",right: "5px" }}>
                        <Menu.Item
                            name='hand paper'
                            onClick={this.handleClickGrag}
                            >
                            <Icon name='hand paper' />
                        </Menu.Item>
                </Menu>
                ) : (
                <Menu inverted icon vertical style={{position: "absolute", top: "17em",right: "5px" }}>
                    <Menu.Item
                        name='hand paper'
                        color="orange"
                        active={true}
                        onClick={this.handleClickGrag}
                        >
                        <Icon name='hand paper' />
                    </Menu.Item>
                </Menu>
                )
            }
            

            <Menu inverted icon vertical style={{position: "absolute", top: "20em",right: "5px" }}>
                <Menu.Item
                    name='zoom in'
                    size="mini"
                    onClick={this.handleZoomPlus}
                    >
                    <Icon name='zoom in' />
                </Menu.Item>
                <Label size="mini" style={{marginRight: "4px", marginLeft: "4px", width: "39px"}}>{`${parseInt(Math.round(100*(2-this.props.zoom))/100*100)}%`}</Label>
                <Menu.Item
                    name='zoom out'
                    size="mini"
                    onClick={this.handleZoomMinus}
                    >
                    <Icon name='zoom out' />
                </Menu.Item>
            </Menu>
        </Fragment>
        );
    }
}
 
export default MenuCanvas;