import React, { Fragment } from 'react';
import { Input, Label, Button } from 'semantic-ui-react';

function Options(props) {
    

    const handleOnMinus = () => {
        if(props.size > 8){
            props.changeSize(props.size-1);
        }
    }

    const handleOnMinus2 = () => {
        if(props.strokeWidth > 1){
            props.changeStrokeWidth(props.strokeWidth-1);
        }
    }

    const handleOnPlus = () => {
        if(props.size < 50){
            props.changeSize(props.size+1);
        }
    }

    const handleOnPlus2 = () => {
        if(props.strokeWidth < 40){
            props.changeStrokeWidth(props.strokeWidth+1);
        }
    }

    const handleOnMinus3 = () => {
        if(props.espacement> 30){
            props.changeEspacement(props.espacement-10);
        }
    }

    const handleOnPlus3 = () => {
        if(props.espacement < 50){
            props.changeEspacement(props.espacement+10);
        }
    }

    const eraseElement = (e) => {
        props.changeEraseMode(1);
    }

    const erase = (e) => {
        props.changeEraseMode(2);
    }
    

    const choix2 = ["paint brush","circle outline","square outline","window minimize outline","long arrow alternate right","arrows alternate horizontal"];
        
    if(props.active === "font"){
        return (           
            <div style={{position: "absolute", bottom: "1em",left: "10px", backgroundColor: "black", borderRadius: "0.2em" }}>
                <Input size="mini" labelPosition='right' type='text'>
                    <Label color="black">Taille : </Label>
                    <Button size="mini" style={{marginLeft: "0px", marginRight: "0px", borderRadius: "0px"}} color="teal" icon="minus" onClick={handleOnMinus}/>
                    <Label style={{borderRadius: "0px"}}>{props.size}</Label>
                    <Button size="mini" style={{marginRight: "0px", marginLeft: "0px", borderRadius: "0px"}} color="teal" icon="plus" onClick={handleOnPlus}/>
                    <Label color="black">px</Label>
                </Input>
            </div>
        )}else if(props.active === "grid layout"){
        return (
            <div style={{position: "absolute", bottom: "1em",left: "10px", backgroundColor: "black", borderRadius: "0.2em" }}>
            <Input size="mini" labelPosition='right' type='text'>
                <Label color="black">Espacement : </Label>
                <Button size="mini" style={{marginLeft: "0px", marginRight: "0px", borderRadius: "0px"}} color="teal" icon="minus" onClick={handleOnMinus3}/>
                <Label style={{borderRadius: "0px"}}>{props.espacement}</Label>
                <Button size="mini" style={{marginRight: "0px", marginLeft: "0px", borderRadius: "0px"}} color="teal" icon="plus" onClick={handleOnPlus3}/>
                <Label color="black">px</Label>
            </Input>
        </div>
        )}else if(props.active === "eraser"){
            return (
                <Fragment>
                    <div style={{position: "absolute", bottom: `${props.eraseMode === 1 ? "1em" : "3.5em"}`,left: "10px", backgroundColor: "black", borderRadius: "6px" }}>
                        
                        <Input size="mini" labelPosition='right' type='text'>
                            <Button size="mini" style={{margin: "4px", borderRadius: "4px"}} color={props.eraseMode === 1 ? "orange" : "teal"} onClick={eraseElement}>Effacer élement</Button>
                            <Button size="mini" style={{margin: "4px", borderRadius: "4px"}} color={props.eraseMode === 2 ? "orange" : "teal"} onClick={erase}>Pinceau blanc</Button>
                        </Input>
                    </div>
                    {
                        props.eraseMode === 2 && (
                            <div style={{position: "absolute", bottom: "1em",left: "10px", backgroundColor: "black", borderRadius: "0.2em" }}>
                            <Input size="mini" labelPosition='right' type='text'>
                                    <Label color="black">Epaisseur : </Label>
                                    <Button size="mini" style={{marginLeft: "0px", marginRight: "0px", borderRadius: "0px"}} color="teal" icon="minus" onClick={handleOnMinus2}/>
                                    <Label style={{borderRadius: "0px"}}>{props.strokeWidth}</Label>
                                    <Button size="mini" style={{marginRight: "0px", marginLeft: "0px", borderRadius: "0px"}} color="teal" icon="plus" onClick={handleOnPlus2}/>
                                    <Label color="black">px</Label>
                                </Input>
                            </div>
                        )
                    }
                </Fragment>
            )}else if(choix2.includes(props.active)){
            return (
                <div style={{position: "absolute", bottom: "1em",left: "10px", backgroundColor: "black", borderRadius: "0.2em" }}>
                    <Input size="mini" labelPosition='right' type='text'>
                        <Label color="black">Epaisseur : </Label>
                        <Button size="mini" style={{marginLeft: "0px", marginRight: "0px", borderRadius: "0px"}} color="teal" icon="minus" onClick={handleOnMinus2}/>
                        <Label style={{borderRadius: "0px"}}>{props.strokeWidth}</Label>
                        <Button size="mini" style={{marginRight: "0px", marginLeft: "0px", borderRadius: "0px"}} color="teal" icon="plus" onClick={handleOnPlus2}/>
                        <Label color="black">px</Label>
                    </Input>
                </div>
            )
    }else{
         return null;
    }
}
 
export default Options;