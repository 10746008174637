import React from 'react';
import { Input, Label, Button } from 'semantic-ui-react';

function OptionPage(props) {
    
    const handleOnMinus = () => {
        if(props.page > 1){     
            props.changePage(props.page-1);
        }
    }

    const handleOnPlus = () => {
        if(props.page < 10){
            
            if(props.page >= props.total){
                props.changeTotalPage(props.page+1);
            }
            props.changePage(props.page+1);
        }
    }


    return ( 
            <div style={{position: "absolute", bottom: "1em",right: "5px" }}>
                <Input size="mini" labelPosition='right' style={{backgroundColor: "black", borderRadius: "0.3em"}}>
                    <Label color="black">Page : </Label>
                    <Button size="mini" style={{marginLeft: "0px", marginRight: "0px",borderRadius: "0px"}} color="teal" icon="minus" onClick={handleOnMinus}/>
                    <Label style={{borderRadius: "0px"}}>  {props.page}  </Label>
                    <Button size="mini" style={{marginRight: "0px", marginLeft: "0px", borderRadius: "0px"}} color="teal" icon="plus" onClick={handleOnPlus}/>
                    <Label color="black">/ {props.total}</Label>
                </Input>
            </div>
         );
}
 
export default OptionPage;