import React, { Component, Fragment } from 'react';
import { Menu, Icon, Responsive } from 'semantic-ui-react';

class Tools extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            selectItem: "paint brush"
        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.erase = this.erase.bind(this);
    }

    handleItemClick(e,{name}){
        this.setState({
            selectItem: name
        });
        this.props.selectItem(name);
    }

    erase(){
      this.props.changeAvertissement(true);
    }

    render() { 
      const { selectItem } = this.state
        return ( 
          <Fragment>
          <Responsive as={Menu} {...Responsive.onlyComputer} style={{position: "absolute", marginLeft: "auto", marginRight: "auto", marginTop: "0px", top: "5px",right: "0px", left: "0px", width : "86em"}} size="mini"  inverted icon='labeled'>
            <Menu.Item
                name='mouse pointer'
                active={selectItem === 'mouse pointer'}
                onClick={this.handleItemClick}
              >
              <Icon name='mouse pointer' />
              Déplacer
            </Menu.Item>
           
           <Menu.Item
              name='paint brush'
              active={selectItem === 'paint brush'}
              onClick={this.handleItemClick}
            >
              <Icon name='paint brush' />
              Pinceau
            </Menu.Item>
    
            <Menu.Item
              name='font'
              active={selectItem === 'font'}
              onClick={this.handleItemClick}
            >
              <Icon name='font' />
              Texte
            </Menu.Item>

            <Menu.Item
              name='circle outline'
              active={selectItem === 'circle outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='circle outline' />
              Ovale
            </Menu.Item>

            <Menu.Item
              name='circle'
              active={selectItem === 'circle'}
              onClick={this.handleItemClick}
            >
              <Icon name='circle' />
              Ovale plein
            </Menu.Item>

            <Menu.Item
              name='square outline'
              active={selectItem === 'square outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='square outline' />
              Rectangle
            </Menu.Item>

            <Menu.Item
              name='square full'
              active={selectItem === 'square full'}
              onClick={this.handleItemClick}
            >
              <Icon name='square full' />
              Rectangle
            </Menu.Item>

            <Menu.Item
              name='window minimize outline'
              active={selectItem === 'window minimize outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='window minimize outline' />
              Ligne
            </Menu.Item>

            <Menu.Item
              name='long arrow alternate right'
              active={selectItem === 'long arrow alternate right'}
              onClick={this.handleItemClick}
            >
              <Icon name='long arrow alternate right' />
              Flèche
            </Menu.Item>

            <Menu.Item
              name='arrows alternate horizontal'
              active={selectItem === 'arrows alternate horizontal'}
              onClick={this.handleItemClick}
            >
              <Icon name='arrows alternate horizontal' />
              Double flèche
            </Menu.Item>

            <Menu.Item
              name='grid layout'
              active={selectItem === 'grid layout'}
              onClick={this.handleItemClick}
            >
              <Icon name='grid layout' />
              Quadrillage
            </Menu.Item>

            <Menu.Item
              name='eraser'
              active={selectItem === 'eraser'}
              onClick={this.handleItemClick}
            >
              <Icon name='eraser' />
              Effacer
            </Menu.Item>

            <Menu.Item
              name='trash alternate'
              onClick={this.erase}
            >
              <Icon name='trash alternate' />
              Effacer tout
            </Menu.Item>
          </Responsive>
          <Responsive as={Menu} {...Responsive.onlyTablet} style={{position: "absolute", marginLeft: "auto", marginRight: "auto", marginTop: "0px", top: "5px",right: "0px", left: "0px", width : "45.1em"}} inverted icon>
            <Menu.Item
                name='mouse pointer'
                active={selectItem === 'mouse pointer'}
                onClick={this.handleItemClick}
              >
              <Icon name='mouse pointer' />
            </Menu.Item>
            
            <Menu.Item
                name='paint brush'
                active={selectItem === 'paint brush'}
                onClick={this.handleItemClick}
              >
              <Icon name='paint brush' />
            </Menu.Item>
    
            <Menu.Item
              name='font'
              active={selectItem === 'font'}
              onClick={this.handleItemClick}
            >
              <Icon name='font' />
            </Menu.Item>

            <Menu.Item
              name='circle outline'
              active={selectItem === 'circle outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='circle outline' />
            </Menu.Item>

            <Menu.Item
              name='circle'
              active={selectItem === 'circle'}
              onClick={this.handleItemClick}
            >
              <Icon name='circle' />
            </Menu.Item>

            <Menu.Item
              name='square outline'
              active={selectItem === 'square outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='square outline' />
            </Menu.Item>

            <Menu.Item
              name='square full'
              active={selectItem === 'square full'}
              onClick={this.handleItemClick}
            >
              <Icon name='square full' />
            </Menu.Item>

            <Menu.Item
              name='window minimize outline'
              active={selectItem === 'window minimize outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='window minimize outline' />
            </Menu.Item>

            <Menu.Item
              name='long arrow alternate right'
              active={selectItem === 'long arrow alternate right'}
              onClick={this.handleItemClick}
            >
              <Icon name='long arrow alternate right' />
            </Menu.Item>

            <Menu.Item
              name='arrows alternate horizontal'
              active={selectItem === 'arrows alternate horizontal'}
              onClick={this.handleItemClick}
            >
              <Icon name='arrows alternate horizontal' />
            </Menu.Item>

            <Menu.Item
              name='grid layout'
              active={selectItem === 'grid layout'}
              onClick={this.handleItemClick}
            >
              <Icon name='grid layout' />
            </Menu.Item>

            <Menu.Item
              name='eraser'
              active={selectItem === 'eraser'}
              onClick={this.handleItemClick}
            >
              <Icon name='eraser' />
            </Menu.Item>

            <Menu.Item
              name='trash alternate'
              onClick={this.erase}
            >
              <Icon name='trash alternate' />
            </Menu.Item>
          </Responsive>
          <Responsive as={Menu} {...Responsive.onlyMobile} style={{position: "absolute", marginLeft: "auto", marginRight: "auto", marginTop: "0px", top: "5px",right: "0px", left: "0px", width : "24.5em"}} inverted icon>
            
            <Menu.Item
                name='mouse pointer'
                active={selectItem === 'mouse pointer'}
                onClick={this.handleItemClick}
              >
              <Icon name='mouse pointer' />
            </Menu.Item>

            <Menu.Item
              name='paint brush'
              active={selectItem === 'paint brush'}
              onClick={this.handleItemClick}
            >
              <Icon name='paint brush' />
            </Menu.Item>
    
            <Menu.Item
              name='font'
              active={selectItem === 'font'}
              onClick={this.handleItemClick}
            >
              <Icon name='font' />
            </Menu.Item>

            <Menu.Item
              name='window minimize outline'
              active={selectItem === 'window minimize outline'}
              onClick={this.handleItemClick}
            >
              <Icon name='window minimize outline' />
            </Menu.Item>

            <Menu.Item
              name='long arrow alternate right'
              active={selectItem === 'long arrow alternate right'}
              onClick={this.handleItemClick}
            >
              <Icon name='long arrow alternate right' />
            </Menu.Item>

            <Menu.Item
              name='eraser'
              active={selectItem === 'eraser'}
              onClick={this.handleItemClick}
            >
              <Icon name='eraser' />
            </Menu.Item>

            <Menu.Item
              name='trash alternate'
              onClick={this.erase}
            >
              <Icon name='trash alternate' />
            </Menu.Item>
          </Responsive>
          </Fragment>
         );
    }
}
 
export default Tools;