import React, { Component } from 'react';
import Fullscreen from "react-full-screen";
import Color from "./PageCanvas/Color";
import Tools from "./PageCanvas/Tools";
import Options from './PageCanvas/Options';
import Canvas from "./PageCanvas/Canvas";
import Tools2 from './PageCanvas/Tools2';
import OptionPage from './OptionPage';
import Avertissement from './PageCanvas/Avertissement';
import Share from './Share';
import Shared from './Shared';
import uuid from 'react-uuid';

const saveSvgAsPng = require('save-svg-as-png');

const imageOptions = {
  scale: 3,
  encoderOptions: 1,
  backgroundColor: 'white',
}

class PageCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        activeItem : "paint brush",
        nombreDePage : 1,
        pageActive: 1,
        color: "black",
        strokeWidth: 2,
        size: 10,
        isFull: false,
        espacement: 30,
        eraseMode : 1,
        eraseOk: false,
        avertissement: false,
        identifiant: "",
        userName: "",
        createByUser: false
     }
     this.selectItem = this.selectItem.bind(this);
     this.changeColor = this.changeColor.bind(this);
     this.changeStrokeWidth = this.changeStrokeWidth.bind(this);
     this.changeSize = this.changeSize.bind(this);
     this.goFull = this.goFull.bind(this);
     this.changeEspacement = this.changeEspacement.bind(this);
     this.changePage = this.changePage.bind(this);
     this.changeTotalPage = this.changeTotalPage.bind(this);
     this.changeEraseMode = this.changeEraseMode.bind(this);
     this.changeEraseOk = this.changeEraseOk.bind(this);
     this.changeAll = this.changeAll.bind(this);
     this.changeAvertissement = this.changeAvertissement.bind(this);
     this.changerIdentifiant = this.changerIdentifiant.bind(this);
     this.changeUsername = this.changeUsername.bind(this);
     this.changerNbpage = this.changerNbpage.bind(this);
     this.changerPageActive = this.changerPageActive.bind(this);
  }

  componentDidMount(){

    this.setState({
      strokeWidth: parseInt(localStorage.getItem('strokeWidth')) || 2,
      color: localStorage.getItem('color') || "black",
      size: parseInt(localStorage.getItem('size')) || 10,
      identifiant: this.props.match.params.id || "",
      adminId: uuid(),
      createByUser: localStorage.getItem('createByUser')  === 'true' || false
    });

  }


  componentDidUpdate(prevProps, prevState){
      if(prevState.strokeWidth !== this.state.strokeWidth){
        localStorage.setItem('strokeWidth', this.state.strokeWidth);
      }
      if(prevState.color !== this.state.color){
        localStorage.setItem('color', this.state.color);
      }
      if(prevState.size !== this.state.size){
        localStorage.setItem('size', this.state.size);
      }

      if(prevState.activeItem !== this.state.activeItem){
          if(this.state.activeItem === "trash alternate"){
            this.setState({
              avertissement: true
            })
          }
      }

      if(prevProps.location !== this.props.location){
        this.setState({
          createByUser: this.props.location.state ? this.props.location.state.createByUser : false
        });
      }

  }

  async changePage(value){
    await this.setState({
       pageActive: value
    });

    if(this.state.identifiant !== ""){
      fetch("https://tableaupost.web-labosims.org/changeNbPages.php", { 
          method: "POST", 
          body: JSON.stringify({
              id: this.state.identifiant, 
              nbpages: this.state.nombreDePage,
              pageactive: value
      }), 
      headers: { 
          "Content-type": "application/json; charset=UTF-8"
      } 
      }).then(response => response.json()) 
      .then(data => {
          console.log(data);
      }).catch((error) => {
          console.log(error);
      })
    }

  }

  changeTotalPage(value){

    this.setState({
      nombreDePage: value
    });

    // on change le nombre de page dans la bdd

    if(this.state.identifiant !== ""){
      fetch("https://tableaupost.web-labosims.org/changeNbPages.php", { 
          method: "POST", 
          body: JSON.stringify({
              id: this.state.identifiant, 
              nbpages: value,
              pageactive: this.state.pageActive
      }), 
      headers: { 
          "Content-type": "application/json; charset=UTF-8"
      } 
      }).then(response => response.json()) 
      .then(data => {
          console.log(data);
      }).catch((error) => {
          console.log(error);
      })
    }

  }


  selectItem(value){
      this.setState({
        activeItem: value
      })
  }

  changeColor(value){
    this.setState({
      color: value
    })
  }

  changeSize(value){
    this.setState({
      size: value
    })
  }

  changeStrokeWidth(value){
    this.setState({
      strokeWidth: value
    })
  }

  goFull(){
    this.setState({ isFull: !this.state.isFull });
  }

  savepng(){
      const length = 6;
      const str = Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
      saveSvgAsPng.saveSvgAsPng(document.getElementById('svg-chart'), `${str}.png`, imageOptions);
  };

  changeEspacement(value){
    this.setState({
        espacement: value
      })
  }

  changeEraseMode(value){
    this.setState({
        eraseMode: value
      })
  } 

  changeUndo(value){
    this.setState({
      undo: value
    });
  }

  changeEraseOk(value){
    this.setState({
      eraseOk : value
    })
  }

  changeAll(value){
      this.setState({
        eraseOk : value,
        avertissement: false
      })
  }

  changeAvertissement(value){
    this.setState({
      avertissement: value
    })
  }

  async changerIdentifiant(value){
    await this.setState({
      identifiant: value
    });

    localStorage.setItem('identifiant', value);
    localStorage.setItem('createByUser', "true");

    this.props.history.push({
        pathname: `/w/${this.state.identifiant}`,
        state: {
          createByUser: true
        }
    });
  }

  changeUsername(value){
    this.setState({
      userName: value
    })
  }

  changerNbpage(value){
    this.setState({
      nombreDePage: value
    })
  }

  changerPageActive(value){
    this.setState({
      pageActive: value
    })
  }

  render(){
    return (
      <Fullscreen
          enabled={this.state.isFull}
          onChange={isFull => this.setState({isFull})}
      >   
            <div style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px",margin: "0px", height: "100%", width: "100%", overflow: "hidden"}}>
              <Canvas changerPageActive={this.changerPageActive} changerNbpage={this.changerNbpage} createByUser={this.state.createByUser} userName={this.state.userName}  changeEraseOk={this.changeEraseOk} eraseOk={this.state.eraseOk} identifiant={this.state.identifiant} eraseMode={this.state.eraseMode} color={this.state.color} espacement={this.state.espacement} strokeWidth={this.state.strokeWidth} fontsize={this.state.size} active={this.state.activeItem} page={this.state.pageActive-1} nbpages={this.state.nombreDePage}/>
            </div>
            <Tools active={this.state.activeItem} selectItem={this.selectItem} changeAvertissement={this.changeAvertissement}/>
            <Options active={this.state.activeItem} size={this.state.size} changeEspacement={this.changeEspacement} changeSize={this.changeSize} strokeWidth={this.state.strokeWidth} eraseMode={this.state.eraseMode} espacement={this.state.espacement} changeStrokeWidth={this.changeStrokeWidth} changeEraseMode={this.changeEraseMode}/>
            <Color color={this.state.color} changeColor={this.changeColor}/>
            <Tools2 isFull={this.state.isFull} goFull={this.goFull} savepng={this.savepng}/>
            <OptionPage changePage={this.changePage} changeTotalPage={this.changeTotalPage} page={this.state.pageActive} total={this.state.nombreDePage} />
            {
              this.state.identifiant === "" ? (
                  <Share changerIdentifiant={this.changerIdentifiant}/>
              ) : (
                  <Shared changeUsername={this.changeUsername} userName={this.state.userName}/>
              )
            }
            <Avertissement open={this.state.avertissement} changeAll={this.changeAll} />
      </Fullscreen>
    );
  }
}

export default PageCanvas;