import React, { Component } from 'react';
import PageCanvas from './components/PageCanvas';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Page404 from './components/Page404';

class App extends Component {

  render(){
    return (
      <div className="App" style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px",margin: "0px", height: "100%", width: "100%"}}>
          <BrowserRouter>
              <Switch>
                <Route path="/" component={PageCanvas} exact={true}/>
                <Route path="/w/:id" component={PageCanvas}/>
                <Route path="*" component={Page404}/>
              </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
