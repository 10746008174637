import React, {Component, Fragment} from 'react';
import MenuCanvas from './MenusCanevas/MenuCanvas';

class Canvas extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            startX: 0,
            startY: 0,
            element: [],
            leselements: [], // a envoyer et recevoir
            grid: [],
            elementTexte: [],
            listeTexte: [], // a envoyer et recevoir
            begin : false,
            beginText: false,
            LastindexElement: 0,
            ListeLastIndex: [],
            listeDeleteElement: [],
            listeDeleteTexte: [],
            pathEdit: "",
            beginEdit : false,
            pointsEdit: [],
            elementEdit: [],
            listeGeneral: [],
            deltaX: 0,
            deltaY: 0,
            grab: false,
            zoom: 1,
            zoomEdit: 1,
            IdEdit: 0,
        }
        this.startDrag = this.startDrag.bind(this);
        this.Drag = this.Drag.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickUndo = this.handleClickUndo.bind(this);
        this.handleClickGrag = this.handleClickGrag.bind(this);
        this.handleZoomPlus = this.handleZoomPlus.bind(this);
        this.handleZoomMinus = this.handleZoomMinus.bind(this);
        this.generateID = this.generateID.bind(this);
        this.liveUpdate = this.liveUpdate.bind(this);
        this.updateAll = this.updateAll.bind(this);

        this.widthC = window.innerWidth;
        this.heightC = window.innerHeight;
    }

    appendToBuffer(pt) {
        this.buffer.push(pt);
        while (this.buffer.length > this.bufferSize) {
            this.buffer.shift();
        }
    };

    generateID() {
        return Array(16)
        .fill(0)
        .map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 97))
        .join('') + 
        Date.now().toString(24);
    }

    async componentDidMount(){

        this.svg = document.querySelector('svg');
        this.pt = this.svg.createSVGPoint();

        this.strPath = "";
        this.buffer = []; 
        this.bufferSize = 3;

        this.pathX = null;
        this.pathY = null;

        if(this.props.identifiant === ""){
        
            await this.setState({
                    leselements : JSON.parse(localStorage.getItem('leselements')) || [],
                    listeTexte: JSON.parse(localStorage.getItem('listeTexte')) || [],   
                    listeGeneral: JSON.parse(localStorage.getItem('listeGeneral')) ||[],
                    listeDeleteElement: JSON.parse(localStorage.getItem('listeDeleteElement')) ||[],
                    listeDeleteTexte: JSON.parse(localStorage.getItem('listeDeleteTexte')) ||[],
                    ListeLastIndex: JSON.parse(localStorage.getItem('ListeLastIndex')) ||[],
                    LastindexElement: parseInt(localStorage.getItem('LastindexElement')) || 1,
                    zoom: parseInt(localStorage.getItem('zoom')) || 1,
                    deltaX: parseInt(localStorage.getItem('deltaX')) || 0,
                    deltaY: parseInt(localStorage.getItem('deltaY')) || 0,
            });

            this.updateAll();

        }
       
            this.interval = setInterval(() => {
                this.liveUpdate();
            }, 2000);

    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    cursorPoint(evt){
        if (evt.type === 'touchstart' || evt.type === 'touchmove') {
            this.pt.x = evt.touches[0].pageX; this.pt.y = evt.touches[0].pageY;
        }else{
            this.pt.x = evt.clientX; this.pt.y = evt.clientY;
        }
        
        return this.pt.matrixTransform(this.svg.getScreenCTM().inverse());
    }

    getAveragePoint(offset){
        var len = this.buffer.length;
        if (len % 2 === 1 || len >= this.bufferSize) {
            var totalX = 0;
            var totalY = 0;
            var pt, i;
            var count = 0;
            for (i = offset; i < len; i++) {
                count++;
                pt = this.buffer[i];
                totalX += pt.x;
                totalY += pt.y;
            }
            return {
                x: totalX / count,
                y: totalY / count
            }
        }
        return null;
    }

    updateSvgPath() {

        var pt = this.getAveragePoint(0);
    
        if (pt) {

            this.strPath += " L " + pt.x + " " + pt.y;
    
            var tmpPath = "";
            for (var offset = 2; offset < this.buffer.length; offset += 2) {
                pt = this.getAveragePoint(offset);
                tmpPath += " L " + pt.x + " " + pt.y;
            }

            const id = this.generateID();
    
            this.setState({
                element: [this.props.userName,id,this.props.page,this.state.LastindexElement,"",(this.strPath+tmpPath), this.props.color, this.props.strokeWidth,"none","","",this.state.zoom],
            });

        }
    }

    componentDidUpdate(prevProps,prevState){

        if(prevProps.active !== this.props.active){

            if(this.props.active !== "font"){

                if(this.state.beginText === true && this.state.elementTexte.length > 0){

                    let leslignes = this.state.elementTexte[3].split("\n");
                    let texte = "";
                    leslignes.forEach((element,index) => {
                        texte += `<tspan x=${this.state.elementTexte[4]} y=${this.state.elementTexte[5]+(this.props.fontsize*1.15/3)*(index+1)}>` + element + "</tspan>";
                    });

                    this.setState({
                        listeTexte: [...this.state.listeTexte, [this.props.userName,this.state.listeTexte[1],this.props.page,this.state.LastindexElement, texte, this.state.elementTexte[4],this.state.elementTexte[5]+(this.props.fontsize*1.15/3),this.props.color,this.props.fontsize/3,this.state.zoom]],
                        listeGeneral: [...this.state.listeGeneral,[this.props.userName,this.state.listeTexte[1],this.props.page,this.state.LastindexElement, texte, this.state.elementTexte[4],this.state.elementTexte[5]+(this.props.fontsize*1.15/3),this.props.color,this.props.fontsize/3, this.state.zoom]],
                        elementTexte: [],
                        beginText: false,
                        ListeLastIndex: [...this.state.ListeLastIndex, this.state.LastindexElement],
                        LastindexElement : this.state.LastindexElement+1
                    });
                }

            }

        }

        if(prevProps.eraseOk !== this.props.eraseOk){

            if(this.props.eraseOk === true){

                document.getElementById("svg-chart").innerHTML = "";

               this.setState({          
                    leselements: this.state.leselements.filter(element => element[2] !== this.props.page),
                    listeTexte: this.state.listeTexte.filter(element => element[2] !== this.props.page),
                    listeGeneral: this.state.listeGeneral.filter(element => element[2] !== this.props.page),
                    listeDeleteElement: this.state.listeDeleteElement.filter(element => element[2] !== this.props.page),
                    listeDeleteTexte: this.state.listeDeleteTexte.filter(element => element[2] !== this.props.page),
                });

               this.props.changeEraseOk(false);

               if(this.props.identifiant !== ""){
                fetch("https://tableaupost.web-labosims.org/add.php", { 
                    method: "POST", 
                    body: JSON.stringify({
                        id: this.props.identifiant, 
                        element: JSON.stringify([])
                }), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                } 
                }).then(response => response.json()) 
                .then(data => {
                    console.log(data);
                }).catch((error) => {
                    console.log(error);
                })
                }

                if(this.props.identifiant !== ""){
                    fetch("https://tableaupost.web-labosims.org/addTexte.php", { 
                        method: "POST", 
                        body: JSON.stringify({
                            id: this.props.identifiant, 
                            element: JSON.stringify(this.state.listeTexte)
                    }), 
                    headers: { 
                        "Content-type": "application/json; charset=UTF-8"
                    } 
                    }).then(response => response.json()) 
                    .then(data => {
                        console.log(data);
                    }).catch((error) => {
                        console.log(error);
                    })
                }

            }

        }

        if(prevProps.createByUser !== this.props.createByUser){
            if(this.props.createByUser === true){
                const envoie = JSON.stringify(this.state.leselements);

                fetch("https://tableaupost.web-labosims.org/create.php", { 
                    method: "POST", 
                    body: JSON.stringify({
                        id: this.props.identifiant,
                        leselements: this.state.leselements,
                        lestextes: this.state.listeTexte
                }), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                } 
                }).then(response => response.json()) 
                .then(data => {
                    console.log(data);
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
 
        if(prevProps.page !== this.props.page){

            this.setState({
                zoom: 1,
                deltaX: 0,
                deltaY: 0,
                grab: false
            });

            this.updateAll();           
        } 

        if(prevState.zoom !== this.state.zoom){

            var allElements = document.getElementById("svg-chart").getElementsByTagName("*");

            for(var i = 0; i < allElements.length; i++) {
                var element = allElements[i];
                var zoom = element.getAttribute("zoom");

                /* var bbox = element.getBBox();

                var scale = this.state.zoom;

                var dx = (1-scale)*(bbox.x+bbox.width/2);
                var dy = (1-scale)*(bbox.y+bbox.height/2); */

                element.setAttributeNS(null,"style","transform-origin: center");
                element.setAttributeNS(null,"transform",`scale(${zoom/this.state.zoom})`);
                
            }

        }

        if(prevState.leselements !== this.state.leselements){
            localStorage.setItem('leselements', JSON.stringify(this.state.leselements));
        }

        if(prevState.listeGeneral !== this.state.listeGeneral){
            localStorage.setItem('listeGeneral', JSON.stringify(this.state.listeGeneral));
        }

        if(prevState.listeTexte !== this.state.listeTexte){
            localStorage.setItem('listeTexte', JSON.stringify(this.state.listeTexte));
        }

        if(prevState.listeDeleteElement !== this.state.listeDeleteElement){
            localStorage.setItem('listeDeleteElement', JSON.stringify(this.state.listeDeleteElement));
        }

        if(prevState.listeDeleteTexte !== this.state.listeDeleteTexte){
            localStorage.setItem('listeDeleteTexte', JSON.stringify(this.state.listeDeleteTexte));
        }

        if(prevState.ListeLastIndex !== this.state.ListeLastIndex){
            localStorage.setItem('ListeLastIndex', JSON.stringify(this.state.ListeLastIndex));
        }

        if(prevState.LastindexElement !== this.state.LastindexElement){
            localStorage.setItem('LastindexElement', this.state.LastindexElement);
        }

        if(prevState.zoom !== this.state.zoom){
            localStorage.setItem('zoom', this.state.zoom);
        }

        if(prevState.deltaX !== this.state.deltaX){
            localStorage.setItem('deltaX', this.state.deltaX);
        }

        if(prevState.deltaY !== this.state.deltaY){
            localStorage.setItem('deltaY', this.state.deltaY);
        }

    }

    liveUpdate(){

        if(!this.state.element){

        var update = false;

            fetch("https://tableaupost.web-labosims.org/listen.php", { 
                method: "POST", 
                body: JSON.stringify({
                    id: this.props.identifiant, 
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                if(parseInt(data[0].pageactive) !== (parseInt(this.props.page)+1)){
                    console.log("update page active");
                    this.props.changerPageActive(parseInt(data[0].pageactive));
                }
                if(parseInt(data[0].pages) !== parseInt(this.props.nbpages)){
                    this.props.changerNbpage(parseInt(data[0].pages));
                    console.log("update nbpages");
                }

                let leselem = JSON.parse(data[0].leselements);

                //console.log(JSON.parse(leselem));

                if(JSON.stringify(leselem) !== JSON.stringify(this.state.leselements)){
                    this.setState({
                        leselements: JSON.parse(leselem)
                    });
                    this.updateAll();
                }

                let lestxts = JSON.parse(data[0].lestextes);
                
                if(JSON.stringify(lestxts) !== JSON.stringify(this.state.listeTexte)){
                    this.setState({
                        listeTexte: JSON.parse(lestxts)
                    });
                    this.updateAll();
                }
                
            }).catch((error) => {
                console.log(error);
            });

        }

    }

    async startDrag(e){

        var loc = this.cursorPoint(e);
      
        if(this.props.active === "eraser" && this.props.eraseMode === 1){

            let filtreElement = null;
  
            try{
                if (e.type === 'touchstart') {
                    filtreElement = this.state.leselements.filter(element => e.touches[0].target.getAttribute("d").includes(element[5]));
                }else{
                    filtreElement = this.state.leselements.filter(element => e.target.getAttribute("d").includes(element[5]));
                }
            }catch(error){
                // erreur
            }

            if(filtreElement){

                this.setState({ 
                    listeDeleteElement: [...this.state.listeDeleteElement,filtreElement[0]],
                    listeGeneral: [...this.state.listeGeneral, filtreElement[0]]
                });

 
                var element = e.target;

                var parent = element.parentNode;
                parent.removeChild(element);


                    this.setState({ 
                        LastindexElement : this.state.LastindexElement+1
                    });
    
                    if (e.type === 'touchstart') {
                        await this.setState({          
                            leselements: this.state.leselements.filter(element => !e.touches[0].target.getAttribute("d").includes(element[5])),
                        });
                    }else{
                        await this.setState({          
                            leselements: this.state.leselements.filter(element => !e.target.getAttribute("d").includes(element[5])),
                        });
                    }

                    if(this.props.identifiant !== ""){
                        fetch("https://tableaupost.web-labosims.org/add.php", { 
                            method: "POST", 
                            body: JSON.stringify({
                                id: this.props.identifiant, 
                                element: JSON.stringify(this.state.leselements)
                        }), 
                        headers: { 
                            "Content-type": "application/json; charset=UTF-8"
                        } 
                        }).then(response => response.json()) 
                        .then(data => {
                            console.log(data);
                        }).catch((error) => {
                            console.log(error);
                        })
                    }

            }else{
                let leTexte = [];


                if (e.type === 'touchmove') {
                    leTexte = this.state.listeTexte.find(element => element[4] === e.touches[0].currentTarget.innerHTML.replace(/"/g, ''));
                }else{
                    leTexte = this.state.listeTexte.find(element => element[4] === e.currentTarget.innerHTML.replace(/"/g, ''));
                }

                console.log(leTexte);

                if(leTexte){
                
                    let index_leTexte = leTexte[3];
        
                    if (e.type === 'touchmove') {
                        this.setState({ 
                            listeDeleteTexte: [...this.state.listeDeleteTexte,this.state.listeTexte.filter(element => element[4] === e.touches[0].currentTarget.innerHTML.replace(/"/g, ''))[0]]
                        });
        
                        this.setState({
                            listeTexte: this.state.listeTexte.filter(element => element[4] !== e.touches[0].currentTarget.innerHTML.replace(/"/g, '')),
                            ListeLastIndex: this.state.ListeLastIndex.filter(element => element !== index_leTexte)
                        });
                    }else{
                        this.setState({ 
                            listeDeleteTexte: [...this.state.listeDeleteTexte,this.state.listeTexte.filter(element => element[4] === e.currentTarget.innerHTML.replace(/"/g, ''))]
                        });
        
                        this.setState({
                            listeTexte: this.state.listeTexte.filter(element => element[4] !== e.currentTarget.innerHTML.replace(/"/g, '')),
                            ListeLastIndex: this.state.ListeLastIndex.filter(element => element !== index_leTexte)
                        });
                    }
        
                    this.setState({
                        listeGeneral: [...this.state.listeGeneral, leTexte[0]],
                    })

                    if(leTexte !== null){
                        var element = e.target.parentNode;

                        var parent = element.parentNode;
                        parent.removeChild(element);
                    }

                }

            }

            localStorage.setItem('listeDeleteElement', JSON.stringify(this.state.listeDeleteElement));
            localStorage.setItem('listeDeleteTexte', JSON.stringify(this.state.listeDeleteTexte));


        }
        else if(this.state.grab === true){

            this.refs.canvas.style.cursor = "grabbing";

                this.setState({
                    startX: loc.x,
                    startY: loc.y,
                    begin: true
                });
            

        }
        else if(this.props.active === "eraser" && this.props.eraseMode === 2){
               
            let first = "";
            let second = "";

            first = "M " + (loc.x) + " " + (loc.y);
            
            second = " L " + (loc.x) + " " + (loc.y) + " M " + (loc.x) + " " + (loc.y);

            var id = this.generateID();

            this.setState({
                    startX: loc.x,
                    startY: loc.y,
                    element: [this.props.userName,id,this.props.page,this.state.LastindexElement,first,second, "white", this.props.strokeWidth,"none","","",this.state.zoom],
                    begin: true
            });
            

        }
        else if(this.props.active === "mouse pointer" && this.state.beginEdit !== true){

            let un_element = [];
            let path = "";

            let points = [];

            let zoom = 1;

            if (e.type === 'touchstart') {

                try{
                    un_element = this.state.leselements.find(element => e.touches[0].target.getAttribute("d").includes(element[5]));
                    zoom = un_element[11];
                    this.setState({
                        elementEdit: [this.state.leselements.indexOf(un_element),un_element],
                        zoomEdit: zoom
                    });
                }catch(error){
                    // erreur
                }

            }else{

                try{
                    un_element = this.state.leselements.find(element => e.target.getAttribute("d").includes(element[5]));
                    zoom = un_element[11];
                    this.setState({
                        elementEdit: [this.state.leselements.indexOf(un_element),un_element],
                        zoomEdit: zoom
                    });
                }catch(error){
                    // erreur
                }

            }

            this.setState({
                startX: loc.x,
                startY: loc.y,
            });

            if (e.type === 'touchstart') {
                path = e.touches[0].target.getAttribute("d");
                this.setState({
                    IdEdit: e.touches[0].target.getAttribute("id")
                })
            }else{
                path = e.target.getAttribute("d");
                this.setState({
                    IdEdit: e.target.getAttribute("id")
                })
            }

            try{
                points = path.split(" ");
                this.setState({
                    beginEdit : true,
                    pointsEdit: points
                });
            }catch(error){
                // erreur
            }
                
            
            if(points.length === 19){

                this.refs.canvas.style.cursor = "move";

            }else if(points.length === 11){

                this.refs.canvas.style.cursor = "move";

            }else if(points.length === 6){

                this.refs.canvas.style.cursor = "move";

            }else{

                try{

                    path = path.replace("M ","")
                    path = path.split(" L ");

                    path = path.map(element => element = element.split(" "));

                    this.pathX = path.map(element => element = parseFloat(element[0]));
                    this.pathY = path.map(element => element = parseFloat(element[1]));

                    const xmin = Math.min.apply(null, this.pathX);
                    const xmax = Math.max.apply(null, this.pathX);

                    const ymin = Math.min.apply(null, this.pathY);
                    const ymax = Math.max.apply(null, this.pathY);

                    const dx = xmax-xmin;
                    const dy = ymax-ymin;

                    this.refs.canvas.style.cursor = "move";

                    this.setState({
                        pointsEdit: [xmin,ymin,dx,dy]
                    });

                }catch(error){

                    this.refs.canvas.style.cursor = "pointer";

                    this.setState({
                        pointsEdit: []
                    });
                }
            }

        }
        else if( this.props.active === "paint brush" ){

            this.buffer = [];
            this.appendToBuffer(loc);
            this.strPath = "M " + loc.x + " " + loc.y + " L " + loc.x + " " + loc.y;

            var id = this.generateID();

            this.setState({
                element: [this.props.userName,id,this.props.page,this.state.LastindexElement,"",this.strPath, this.props.color, this.props.strokeWidth,"none","","",this.state.zoom],
                begin: true
            });

        }else if(this.props.active === "window minimize outline" || this.props.active === "square outline" || this.props.active === "square full" || this.props.active === "long arrow alternate right" || this.props.active === "arrows alternate horizontal" || this.props.active === "grid layout"){

            let first = "";
            let second = "";

            first = "M " + (loc.x) + " " + (loc.y);

            var id = this.generateID();

            this.setState({
                startX: loc.x,
                startY: loc.y,
                element: [this.props.userName,id,this.props.page,this.state.LastindexElement,first,second, this.props.color, this.props.strokeWidth,"none","","",this.state.zoom],
                begin: true
            });

        }else if(this.props.active === "circle outline" || this.props.active === "circle"){

            var id = this.generateID();

            this.setState({
                startX: loc.x,
                startY: loc.y,
                element: [this.props.userName,id,this.props.page,this.state.LastindexElement,""],
                begin: true
            });

        }else if(this.props.active === "font"){
            
            if(this.state.beginText !== true){

                this.setState({
                    startX: loc.x,
                    startY: loc.y,
                    elementTexte: [this.props.userName,0,this.props.page,"",loc.x, loc.y,this.state.zoom],
                    beginText: true
                });

            }else if(this.state.beginText === true && this.state.elementTexte.length > 0){

                var id = this.generateID();

                let leslignes = this.state.elementTexte[3].split("\n");
                let texte = "";
                leslignes.forEach((element,index) => {
                    texte += `<tspan x=${this.state.elementTexte[4]} y=${this.state.elementTexte[5]+(this.props.fontsize*1.15/3)*(index+1)}>` + element + "</tspan>";
                });

                var xmlns = "http://www.w3.org/2000/svg";

                var path = document.createElementNS(xmlns, "text");

                path.setAttributeNS(null, 'x', this.state.elementTexte[4]);
                path.setAttributeNS(null, 'y', this.state.elementTexte[5]);
                path.setAttributeNS(null, 'fill', this.props.color);
                path.setAttributeNS(null, 'id', id);
                path.setAttributeNS(null, 'zoom', this.state.zoom);
                path.setAttributeNS(null, 'font-size', this.props.fontsize/3);
                path.setAttributeNS(null, 'transform', `scale(1)`);
                path.setAttributeNS(null, 'style', "transformOrigin: center");

                path.innerHTML = texte;
                
                this.refs.canvas.appendChild(path);

                await this.setState({
                    listeTexte: [...this.state.listeTexte, [this.props.userName,id,this.props.page,this.state.LastindexElement, texte, this.state.elementTexte[4],this.state.elementTexte[5]+(this.props.fontsize*1.15/3),this.props.color,this.props.fontsize/3,this.state.zoom]],
                    listeGeneral: [...this.state.listeGeneral,[this.props.userName,id,this.props.page,this.state.LastindexElement, texte, this.state.elementTexte[4],this.state.elementTexte[5]+(this.props.fontsize*1.15/3),this.props.color,this.props.fontsize/3, this.state.zoom]],
                    elementTexte: [],
                    beginText: false,
                    ListeLastIndex: [...this.state.ListeLastIndex, this.state.LastindexElement],
                    LastindexElement : this.state.LastindexElement+1
                });

                if(this.props.identifiant !== ""){
                    fetch("https://tableaupost.web-labosims.org/addTexte.php", { 
                        method: "POST", 
                        body: JSON.stringify({
                            id: this.props.identifiant, 
                            element: JSON.stringify(this.state.listeTexte)
                    }), 
                    headers: { 
                        "Content-type": "application/json; charset=UTF-8"
                    } 
                    }).then(response => response.json()) 
                    .then(data => {
                        console.log(data);
                    }).catch((error) => {
                        console.log(error);
                    })
                }

            }
        }

        if(this.state.element){

            var xmlns = "http://www.w3.org/2000/svg";

            try{

                if(this.state.element[8].indexOf("url") !== -1){
    
                    var pattern = document.createElementNS(xmlns, "pattern");
                    pattern.setAttributeNS(null, 'width', this.state.element[7]/3);
                    pattern.setAttributeNS(null, 'height', this.state.element[7]/3);
                    pattern.setAttributeNS(null, 'patternUnits', "userSpaceOnUse");
                    pattern.setAttributeNS(null, 'id', this.state.element[8].replace("url(#","").replace(")",""));
    
                    var path = document.createElementNS(xmlns, "path");
                    path.setAttributeNS(null, 'stroke', this.state.element[9]);
                    path.setAttributeNS(null, 'stroke-width', 1);
                    path.setAttributeNS(null, 'd', ("M " + this.state.element[7]/3 + " 0 L 0 0 0 " + element[7]/3) );
                    path.setAttributeNS(null, 'fill', "none");
    
                    pattern.appendChild(path);
                    this.refs.defs.appendChild(pattern);
    
                }
                        
            }catch{
                // rien a ajouter
            }

            var path = document.createElementNS(xmlns, "path");
            path.setAttributeNS(null, 'stroke', this.state.element[6]);
            path.setAttributeNS(null, 'stroke-width', this.state.element[7]/3);
            path.setAttributeNS(null, 'stroke-linejoin', "round");
            path.setAttributeNS(null, 'stroke-linecap', "round");
            path.setAttributeNS(null, 'd', (this.state.element[4]+this.state.element[5]));
            path.setAttributeNS(null, 'fill', this.state.element[8]);
            path.setAttributeNS(null, 'opacity', 1.0);
            path.setAttributeNS(null, 'id', "elementEnCours");
            path.setAttributeNS(null, 'zoom', this.state.element[11]);
            path.setAttributeNS(null, 'marker-start', `url(#${this.state.element[10]})`);
            path.setAttributeNS(null, 'marker-end', `url(#${this.state.element[9]})`);
            path.setAttributeNS(null, 'style', `transformOrigin: center,transform: scale(1)`);
            
            this.refs.canvas.appendChild(path); 

            if(this.props.identifiant !== ""){
                fetch("https://tableaupost.web-labosims.org/add.php", { 
                    method: "POST", 
                    body: JSON.stringify({
                        id: this.props.identifiant, 
                        element: JSON.stringify(this.state.leselements)
                }), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                } 
                }).then(response => response.json()) 
                .then(data => {
                    //console.log(data);
                }).catch((error) => {
                    console.log(error);
                })
            }

        }

    }

    async handleClickUndo(){

        let choix = 0;

        if(JSON.stringify(this.state.listeGeneral[this.state.listeGeneral.length-1]) === JSON.stringify(this.state.leselements[this.state.leselements.length-1])){
            choix = 1;
        }else if(JSON.stringify(this.state.listeGeneral[this.state.listeGeneral.length-1]) === JSON.stringify(this.state.listeTexte[this.state.listeTexte.length-1])){
            choix = 2;
        }else if(JSON.stringify(this.state.listeGeneral[this.state.listeGeneral.length-1]) === JSON.stringify(this.state.listeDeleteElement[this.state.listeDeleteElement.length-1])){
            choix = 3;
        }else if(JSON.stringify(this.state.listeGeneral[this.state.listeGeneral.length-1]) === JSON.stringify(this.state.listeDeleteTexte[this.state.listeDeleteTexte.length-1])){
            choix = 4;
        }else{
            choix = 0;
        }

        if(choix === 1){

            var id = this.state.listeGeneral[this.state.listeGeneral.length-1][1];

            var parent = document.querySelector('#svg-chart');
            var child = parent ? parent.querySelector(`#${id}`) : null;

            if(child !== null){

                this.refs.canvas.removeChild(child);

                let lesindex = this.state.ListeLastIndex;
                lesindex.pop();

                let leselements = this.state.leselements;
                
                let listeGeneral = this.state.listeGeneral;
                listeGeneral.pop();
                
                leselements.pop();

                this.setState({
                    leselements:leselements,
                    ListeLastIndex: lesindex,
                    listeGeneral: listeGeneral
                });

            }
                
        }else if(choix === 2){

            var id = this.state.listeGeneral[this.state.listeGeneral.length-1][1];

            var parent = document.querySelector('#svg-chart');
            var child = parent ? parent.querySelector(`#${id}`) : null;

            if(child !== null){

                this.refs.canvas.removeChild(child);

                let lesTextes = this.state.listeTexte;
                lesTextes.pop();

                let listeGeneral = this.state.listeGeneral;
                listeGeneral.pop();

                await this.setState({
                    listeTexte: lesTextes,
                    listeGeneral: listeGeneral
                });

            }

        }else if(choix === 3){

                let leselementsDelete = this.state.listeDeleteElement;

                var xmlns = "http://www.w3.org/2000/svg";

                let leselements = this.state.leselements;

                // on ajoute d'abord le dernier element supprimé à la liste des élements affiché

                leselements.push(leselementsDelete[leselementsDelete.length-1]);

                // on trie les élèments affiché

                leselements.sort((a,b) => a[3] - b[3]);

                // on supprime le dernier élement suppprimé

                leselementsDelete.pop();

                // on supprime le dernier élément de la liste général

                let listeGeneral = this.state.listeGeneral;
                listeGeneral.pop();

                await this.setState({
                    leselements: leselements,
                    listeDeleteElement: leselementsDelete,
                    listeGeneral: listeGeneral,
                });

                this.updateAll();
 
        }else if(choix === 4){

            let lesTextesDelete = this.state.listeDeleteTexte;

            let lesTextes = this.state.listeTexte;

            lesTextes.push(lesTextesDelete[lesTextesDelete.length-1]);

            lesTextes.sort((a,b) => a[3] - b[3]);

            lesTextesDelete.pop();

            let listeGeneral = this.state.listeGeneral;
            listeGeneral.pop();

            await this.setState({
                listeTexte: lesTextes,
                listeDeleteTexte: lesTextesDelete,
                listeGeneral: listeGeneral
            });

            this.updateAll();

        }

        localStorage.setItem('leselements', JSON.stringify(this.state.leselements));
        localStorage.setItem('listeGeneral', JSON.stringify(this.state.listeGeneral));
        localStorage.setItem('listeTexte', JSON.stringify(this.state.listeTexte));

    }

    updateAll(){

        var xmlns = "http://www.w3.org/2000/svg";
        
        try{
            
                this.refs.canvas.innerHTML = "";
                this.refs.defs.innerHTML = "";
        

                this.state.leselements.map(element => {

                    if(element[2] === this.props.page){

                        try{

                            if(element[9].length > 6){

                                var marker = document.createElementNS(xmlns, "marker");
                                marker.setAttribute("id", element[9]);
                                marker.setAttribute("markerWidth", 9);
                                marker.setAttribute("markerHeight", 6);
                                marker.setAttribute("markerUnits", "strokeWidth");
                                marker.setAttribute("refX", 0);
                                marker.setAttribute("refY", 3);
                                marker.setAttribute("orient", "auto");
                
                                var polygon = document.createElementNS(xmlns, "polygon");
                                polygon.setAttribute("points","0 0, 9 3, 0 6");
                                polygon.setAttribute("fill",element[6]);
                
                                marker.appendChild(polygon);
                                this.refs.defs.appendChild(marker);
                            }

                        }catch{
                            // rien a ajouter
                        }
            
                        try{

                            if(element[10].length > 6){
                
                                var marker = document.createElementNS(xmlns, "marker");
                                marker.setAttribute("id", element[10]);
                                marker.setAttribute("markerWidth", 9);
                                marker.setAttribute("markerHeight", 6);
                                marker.setAttribute("markerUnits", "strokeWidth");
                                marker.setAttribute("refX", 9);
                                marker.setAttribute("refY", 3);
                                marker.setAttribute("orient", "auto");
                
                                var polygon = document.createElementNS(xmlns, "polygon");
                                polygon.setAttribute("points","9 0, 9 6, 0 3");
                                polygon.setAttribute("fill",element[6]);
                
                                marker.appendChild(polygon);
                                this.refs.defs.appendChild(marker);
                
                            }
            
                        }catch{
                            // rien a ajouter
                        }
            
                        try{

                            if(element[8].indexOf("url") !== -1){
                
                                var pattern = document.createElementNS(xmlns, "pattern");
                                pattern.setAttributeNS(null, 'width', element[7]/3);
                                pattern.setAttributeNS(null, 'height', element[7]/3);
                                pattern.setAttributeNS(null, 'patternUnits', "userSpaceOnUse");
                                pattern.setAttributeNS(null, 'id', element[8].replace("url(#","").replace(")",""));
                
                                var path = document.createElementNS(xmlns, "path");
                                path.setAttributeNS(null, 'stroke', element[9]);
                                path.setAttributeNS(null, 'stroke-width', 1);
                                path.setAttributeNS(null, 'd', ("M " + element[7]/3 + " 0 L 0 0 0 " + element[7]/3) );
                                path.setAttributeNS(null, 'fill', "none");
                
                                pattern.appendChild(path);
                                this.refs.defs.appendChild(pattern);
                
                            }
                                    
                        }catch{
                            // rien a ajouter
                        }
        
                        var path = document.createElementNS(xmlns, "path");
                        path.setAttributeNS(null, 'stroke', element[6]);
                        path.setAttributeNS(null, 'stroke-width', element[7]/3);
                        path.setAttributeNS(null, 'stroke-linejoin', "round");
                        path.setAttributeNS(null, 'stroke-linecap', "round");
                        path.setAttributeNS(null, 'd', (element[4]+element[5]));
                        path.setAttributeNS(null, 'fill', element[8]);
                        path.setAttributeNS(null, 'opacity', 1.0);
                        path.setAttributeNS(null, 'id', element[1]);
                        path.setAttributeNS(null, 'zoom', element[11]);
                        path.setAttributeNS(null, 'marker-start', `url(#${element[10]})`);
                        path.setAttributeNS(null, 'marker-end', `url(#${element[9]})`);
                        path.setAttributeNS(null, 'transform', `scale(${element[11]/this.state.zoom})`);
                        path.setAttributeNS(null,"style","transform-origin: center");

                        this.refs.canvas.appendChild(path); 

                    }
                });

                this.state.listeTexte.map(element => {

                    if(element[2] === this.props.page){
    
                        var xmlns = "http://www.w3.org/2000/svg";
    
                        var path = document.createElementNS(xmlns, "text");
    
                        path.setAttributeNS(null, 'x', element[5]);
                        path.setAttributeNS(null, 'y', element[6]);
                        path.setAttributeNS(null, 'fill', element[7]);
                        path.setAttributeNS(null, 'font-size', element[8]);
                        path.setAttributeNS(null, 'id', element[1]);
                        path.setAttributeNS(null, 'transform', `scale(${element[9]/this.state.zoom})`);
                        path.setAttributeNS(null,"style","transform-origin: center");
    
                        path.innerHTML = element[4];
                        
                        this.refs.canvas.appendChild(path);   
                    }
                });

            }catch(error){
                //rien
            }
 
    }

    Drag(e){

        var loc = this.cursorPoint(e);

        if(this.state.grab === true && this.state.begin === true){

            this.setState({
                deltaX: this.state.deltaX + loc.x - this.state.startX,
                deltaY: this.state.deltaY + loc.y - this.state.startY,
            });

        }
        else if(this.props.active === "mouse pointer"){
        
            if(this.state.beginEdit === true){

                let deltaX = 0;
                let deltaY = 0;

                deltaX = (loc.x) - this.state.startX;
                deltaY = (loc.y) - this.state.startY;


                if(this.state.pointsEdit.length === 19){

                    let x = parseInt(this.state.pointsEdit[1])+deltaX;
    
                    let y = parseInt(this.state.pointsEdit[3]-this.state.pointsEdit[7])+deltaY;
    
                    let dx = this.state.pointsEdit[5]*2;
    
                    let dy = this.state.pointsEdit[7]*2;

                    let rx = dx/2;
                    let ry = dy/2;
   
                    const cx = x + rx;
                    const cy = y + ry;

                    let pathCercle = `M ${cx-rx} , ${cy} a ${rx} , ${ry} 0 1,0 ${rx*2} ,0a ${rx} , ${ry} 0 1,0 ${-1*rx*2} ,0`;

                    var modif = document.getElementById(this.state.IdEdit);
                    
                    modif.setAttributeNS(null,"d",pathCercle);

                    let elemEdit = this.state.elementEdit;
                    elemEdit[1][5] = pathCercle;

                    let tousleselement = this.state.leselements;

                    const lindex = this.state.listeGeneral.indexOf(tousleselement[elemEdit[0]]);

                    let listeGeneral = this.state.listeGeneral;
                    listeGeneral[lindex] = elemEdit[1];

                    tousleselement[elemEdit[0]] = elemEdit[1];

                    this.setState({
                        leselements: tousleselement,
                        listeGeneral: listeGeneral
                    })
    
                }else if(this.state.pointsEdit.length === 11){
    
                    let x = parseInt(this.state.pointsEdit[1])+deltaX;
    
                    let y = parseInt(this.state.pointsEdit[2])+deltaY;
    
                    let dx = this.state.pointsEdit[4];
    
                    let dy = this.state.pointsEdit[6];

                    var modif = document.getElementById(this.state.IdEdit);
                    
                    modif.setAttributeNS(null,"d",`M ${x} ${y} h ${dx} v ${dy} h ${-1*dx} v ${-1*dy}`);

                    let part1 = `M ${x} ${y}`;
                    let part2 = ` h ${dx} v ${dy} h ${-1*dx} v ${-1*dy}`;

                    let elemEdit = this.state.elementEdit;
                    elemEdit[1][4] = part1;
                    elemEdit[1][5] = part2;

                    let tousleselement = this.state.leselements;

                    const lindex = this.state.listeGeneral.indexOf(tousleselement[elemEdit[0]]);

                    let listeGeneral = this.state.listeGeneral;
                    listeGeneral[lindex] = elemEdit[1];

                    tousleselement[elemEdit[0]] = elemEdit[1];

                    this.setState({
                        leselements: tousleselement,
                        listeGeneral: listeGeneral
                    })

                }else if(this.state.pointsEdit.length === 6){

                    let x = parseInt(this.state.pointsEdit[1])+parseInt(deltaX);
    
                    let y = parseInt(this.state.pointsEdit[2])+parseInt(deltaY);
    
                    let dx = parseInt(this.state.pointsEdit[4])-parseInt(this.state.pointsEdit[1]);

                    let dy = parseInt(this.state.pointsEdit[5])-parseInt(this.state.pointsEdit[2]);

                    let part1 = `M ${x} ${y}`;
                    let part2 = ` L ${x+dx} ${y+dy}`;

                    var modif = document.getElementById(this.state.IdEdit);
                    
                    modif.setAttributeNS(null,"d",`M ${x} ${y} L ${x+dx} ${y+dy}`);

                    let elemEdit = this.state.elementEdit;
                    elemEdit[1][4] = part1;
                    elemEdit[1][5] = part2;

                    let tousleselement = this.state.leselements;

                    const lindex = this.state.listeGeneral.indexOf(tousleselement[elemEdit[0]]);

                    let listeGeneral = this.state.listeGeneral;
                    listeGeneral[lindex] = elemEdit[1];

                    tousleselement[elemEdit[0]] = elemEdit[1];

                    this.setState({
                        leselements: tousleselement,
                        listeGeneral: listeGeneral
                    })

                }else{

                    try{

                        let x = parseInt(this.state.pointsEdit[0])+parseInt(deltaX);
    
                        let y = parseInt(this.state.pointsEdit[1])+parseInt(deltaY);
        
                        let dx = parseInt(this.state.pointsEdit[2]);

                        let dy = parseInt(this.state.pointsEdit[3]);

                        const newpathX = this.pathX.map(element => element = parseFloat(element)+parseFloat(deltaX));
                        const newpathY = this.pathY.map(element => element = parseFloat(element)+parseFloat(deltaY));

                        let elemEdit = this.state.elementEdit;

                        let newPath = "M " + newpathX[0] + " " + newpathY[0];

                        for(var i=1; i < newpathX.length; i++){

                            newPath += " L " + newpathX[i] + " " + newpathY[i];

                        }

                        var modif = document.getElementById(this.state.IdEdit);
                    
                        modif.setAttributeNS(null,"d",newPath);

                        elemEdit[1][5] = newPath;

                        let tousleselement = this.state.leselements;

                        const lindex = this.state.listeGeneral.indexOf(tousleselement[elemEdit[0]]);

                        let listeGeneral = this.state.listeGeneral;
                        listeGeneral[lindex] = elemEdit[1];

                        tousleselement[elemEdit[0]] = elemEdit[1];

                        this.setState({
                            leselements: tousleselement,
                            listeGeneral: listeGeneral
                        })
        
                    }catch(error){
                        this.setState({
                            pointsEdit: []
                        });
                    }

                }

            }

            localStorage.setItem('leselements', JSON.stringify(this.state.leselements));
            localStorage.setItem('listeGeneral', JSON.stringify(this.state.listeGeneral));
            localStorage.setItem('listeDeleteElement', JSON.stringify(this.state.listeDeleteElement));
            localStorage.setItem('listeDeleteTexte', JSON.stringify(this.state.listeDeleteTexte));

            if(this.props.identifiant !== ""){
                fetch("https://tableaupost.web-labosims.org/add.php", { 
                    method: "POST", 
                    body: JSON.stringify({
                        id: this.props.identifiant, 
                        element: JSON.stringify(this.state.leselements)
                }), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                } 
                }).then(response => response.json()) 
                .then(data => {
                    console.log(data);
                }).catch((error) => {
                    console.log(error);
                })
            }

        }
        else if(this.state.begin === true){
           
            if(this.props.active === "window minimize outline"){

               let second = " L " + (loc.x) + " " + (loc.y);

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement,this.state.element[4], second, this.props.color, this.props.strokeWidth,"none","","",this.state.zoom]
                });

            }
            else if(this.props.active === "paint brush"){

                this.appendToBuffer(loc);
                this.updateSvgPath();

            }else if(this.props.active === "eraser" && this.props.eraseMode === 2){

                let second = "";

                if(this.state.element[5] !== undefined){
                    second = this.state.element[5] + " L " + (loc.x) + " " + (loc.y) +  " M " + (loc.x) + " " + (loc.y);
                }else{
                    second = "L " + (loc.x) + " " + (loc.y) +  " M " + (loc.x) + " " + (loc.y);
                }
                
                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement,this.state.element[4], second, "white", this.props.strokeWidth,"none","","",this.state.zoom]
                });


            }else if(this.props.active === "square outline"){

                let second = " h " + (loc.x - this.state.startX) + " v " + (loc.y - this.state.startY) + " h " + -(loc.x - this.state.startX) + " v " + -(loc.y - this.state.startY);

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, this.props.color, this.props.strokeWidth,"none","","",this.state.zoom]
                });

            }else if(this.props.active === "square full"){

                let second = " h " + (loc.x - this.state.startX) + " v " + (loc.y - this.state.startY) + " h " + -(loc.x - this.state.startX) + " v " + -(loc.y - this.state.startY);

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, this.props.color, 2, this.props.color,"","",this.state.zoom]
                });

            }else if(this.props.active === "circle outline"){

                const rx = (loc.x - this.state.startX)/2;
                const ry = (loc.y - this.state.startY)/2;

                const cx = this.state.startX + rx;
                const cy = this.state.startY + ry;
                
                const second = `M ${cx-rx} , ${cy} a ${rx} , ${ry} 0 1,0 ${rx*2} ,0a ${rx} , ${ry} 0 1,0 ${-1*rx*2} ,0`;

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, this.props.color, this.props.strokeWidth, "none","","",this.state.zoom]
                });

            }else if(this.props.active === "circle"){

                let rx = (loc.x - this.state.startX)/2;
                let ry = (loc.y - this.state.startY)/2;

                const cx = this.state.startX + rx;
                const cy = this.state.startY + ry;
                
                const second = `M ${cx-rx} , ${cy} a ${rx} , ${ry} 0 1,0 ${rx*2} ,0a ${rx} , ${ry} 0 1,0 ${-1*rx*2} ,0`;

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement,this.state.element[4], second, this.props.color, 2, this.props.color,"","",this.state.zoom]
                });

            }else if(this.props.active === "long arrow alternate right"){

                const second = " L " + (loc.x) + " " + (loc.y);

                const uid = this.generateID();

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, this.props.color, this.props.strokeWidth,"none",uid,"",this.state.zoom],
                });

            }else if(this.props.active === "arrows alternate horizontal"){

                const second = " L " + (loc.x) + " " + (loc.y);

                const uid = this.generateID();
                const uid2 = this.generateID();

                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, this.props.color, this.props.strokeWidth,"none",uid,uid2,this.state.zoom],
                });

            }else if(this.props.active === "grid layout"){

                let second = " h " + (loc.x - this.state.startX) + " v " + (loc.y - this.state.startY) + " h " + -(loc.x - this.state.startX) + " v " + -(loc.y - this.state.startY);

                const uid = this.generateID();
                
                this.setState({
                    element: [this.props.userName,this.state.element[1],this.props.page,this.state.LastindexElement, this.state.element[4], second, "none", this.props.espacement, `url(#${uid})`,this.props.color,"",this.state.zoom],
                    grid: [this.state.grid[0],this.state.grid[1],this.props.page,this.state.zoom,this.props.espacement,uid,this.props.color]
                });

            }

        }

        if(this.state.element.length > 5){

            var xmlns = "http://www.w3.org/2000/svg";

            try{

                if(this.state.element[8].indexOf("url") !== -1){
                
                    var pattern = document.createElementNS(xmlns, "pattern");
                    pattern.setAttributeNS(null, 'width', this.state.element[7]/3);
                    pattern.setAttributeNS(null, 'height', this.state.element[7]/3);
                    pattern.setAttributeNS(null, 'patternUnits', "userSpaceOnUse");
                    pattern.setAttributeNS(null, 'id', this.state.element[8].replace("url(#","").replace(")",""));
    
                    var path = document.createElementNS(xmlns, "path");
                    path.setAttributeNS(null, 'stroke', this.state.element[9]);
                    path.setAttributeNS(null, 'stroke-width', 1);
                    path.setAttributeNS(null, 'd', ("M " + this.state.element[7]/3 + " 0 L 0 0 0 " + this.state.element[7]/3) );
                    path.setAttributeNS(null, 'fill', "none");
    
                    pattern.appendChild(path);
                    this.refs.defs.appendChild(pattern);

                }

                if(this.state.element[4].indexOf("M") !== -1 || this.state.element[5].indexOf("M") !== -1){
   
                    let path2 = document.getElementById("elementEnCours");

                    path2.setAttributeNS(null, 'stroke', this.state.element[6]);
                    path2.setAttributeNS(null, 'stroke-width', this.state.element[7]/3);
                    path2.setAttributeNS(null, 'stroke-linejoin', "round");
                    path2.setAttributeNS(null, 'stroke-linecap', "round");
                    path2.setAttributeNS(null, 'd', (this.state.element[4]+this.state.element[5]));
                    path2.setAttributeNS(null, 'fill', this.state.element[8]);
                    path2.setAttributeNS(null, 'opacity', 1.0);
                    path2.setAttributeNS(null, 'id', "elementEnCours");
                    path2.setAttributeNS(null, 'zoom', this.state.element[11]);
                    path2.setAttributeNS(null, 'marker-start', `url(#${this.state.element[10]})`);
                    path2.setAttributeNS(null, 'marker-end', `url(#${this.state.element[9]})`);
                    path2.setAttributeNS(null, 'style', `transformOrigin: center,transform: scale(1)`);
            
                }

            }catch(error){
                // rien
            }
    

        }

    }

    async stopDrag(e){

        var loc = this.cursorPoint(e);

        if(this.state.grab === true){
            await this.setState({
                grab: false,
                begin: false
            })
        }
        
        if(this.state.begin === true){

            if(this.state.element.length > 5){

                let path = document.getElementById("elementEnCours");
    
                if(path !== null){
                    path.setAttributeNS(null, 'stroke', this.state.element[6]);
                    path.setAttributeNS(null, 'stroke-width', this.state.element[7]/3);
                    path.setAttributeNS(null, 'stroke-linejoin', "round");
                    path.setAttributeNS(null, 'stroke-linecap', "round");
                    path.setAttributeNS(null, 'd', (this.state.element[4]+this.state.element[5]));
                    path.setAttributeNS(null, 'fill', this.state.element[8]);
                    path.setAttributeNS(null, 'opacity', 1.0);
                    path.setAttributeNS(null, 'id', "elementEnCours");
                    path.setAttributeNS(null, 'zoom', this.state.element[11]);
                    path.setAttributeNS(null, 'marker-start', `url(#${this.state.element[10]})`);
                    path.setAttributeNS(null, 'marker-end', `url(#${this.state.element[9]})`);
                    path.setAttributeNS(null, 'style', `transformOrigin: center`);
                    path.setAttributeNS(null, 'transform', 'scale(1)');
                }
    
            }
             
            await this.setState({
                leselements: [...this.state.leselements, this.state.element],
                listeGeneral: [...this.state.listeGeneral, this.state.element],
                element: [],
                grid: [],
                begin: false,
                ListeLastIndex: [...this.state.ListeLastIndex, this.state.LastindexElement],
                LastindexElement : this.state.LastindexElement+1
            });  

            if(this.props.identifiant !== ""){
                fetch("https://tableaupost.web-labosims.org/add.php", { 
                    method: "POST", 
                    body: JSON.stringify({
                        id: this.props.identifiant, 
                        element: JSON.stringify(this.state.leselements)
                }), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                } 
                }).then(response => response.json()) 
                .then(data => {
                    //console.log(data);
                }).catch((error) => {
                    console.log(error);
                })
            }

            localStorage.setItem('leselements', JSON.stringify(this.state.leselements));
            localStorage.setItem('listeGeneral', JSON.stringify(this.state.listeGeneral));
            localStorage.setItem('listeTexte', JSON.stringify(this.state.listeTexte));
            localStorage.setItem('ListeLastIndex', JSON.stringify(this.state.ListeLastIndex));
            localStorage.setItem('LastindexElement', this.state.LastindexElement);
                
            this.updateAll();

        }

        this.setState({
            beginEdit : false,
            pointsEdit: [],
            IdEdit: 0,
        });


        if(this.state.grab === true){

            this.refs.canvas.style.cursor = "grab";

        }else{
            this.refs.canvas.style.cursor = "pointer";
        }

        this.strPath = "";

    }

    handleChange(e) {
        const elem = this.state.elementTexte;
        this.setState({elementTexte: [this.props.userName,0,this.props.page,e.target.value,elem[4],elem[5]]});
    }

    async handleClickGrag() {
        
        if(this.state.grab === false){
            await this.setState({
                grab: true
            });
            this.refs.canvas.style.cursor = "grab";
        }else{
            await this.setState({
                grab: false
            });
            this.refs.canvas.style.cursor = "pointer";
        }      
    }

    handleZoomPlus(){
        this.setState({
            zoom: this.state.zoom-0.05
        });
    }

    handleZoomMinus(){
        this.setState({
            zoom: this.state.zoom+0.05
        });
    }
     
    render() {
        return (
        <Fragment>
        <div ref="screen" style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px",margin: "0px", height: "100%", width: "100%", overflow: "hidden", cursor: "pointer",backgroundColor: "white"}} onMouseDown={this.startDrag} onTouchStart={this.startDrag} onMouseMove={this.Drag} onTouchMove={this.Drag} onMouseUp={this.stopDrag} onTouchEnd={this.stopDrag}>
          
          <svg viewBox={`${-1*parseInt(this.state.deltaX)} ${-1*parseInt(this.state.deltaY)} ${parseInt(this.widthC/3)} ${parseInt(this.heightC/3)}`} width="100%" height="100%" style={{backgroundColor: "none", position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px",margin: "0px"}}>
                <defs ref="defs">
                {
                    this.state.grid.length > 0 && (
                        <pattern id={this.state.grid[5]} width={this.state.grid[4]/3} height={this.state.grid[4]/3} patternUnits="userSpaceOnUse">
                            <path d={ "M " + this.state.grid[4]/3 + " 0 L 0 0 0 " + this.state.grid[4]/3 } fill="none" stroke={this.props.color} strokeWidth="2/3"/>
                        </pattern>
                    )
                }
                </defs>
          </svg>

          <svg id="svg-chart" ref="canvas" viewBox={`${-1*parseInt(this.state.deltaX)} ${-1*parseInt(this.state.deltaY)} ${parseInt(this.widthC/3)} ${parseInt(this.heightC/3)}`} width="100%" height="100%" style={{backgroundColor: "none", position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px",margin: "0px"}} >              
                
          </svg>
          </div>
          {
            // Affiche si on commence a écrire
            (this.state.beginText === true) && (
                <textarea id="textarea" wrap="off" style={{position: "fixed", top: this.state.elementTexte[5]*3+this.state.deltaY*3 , left: this.state.elementTexte[4]*3+this.state.deltaX*3, fontSize: `${this.props.fontsize}px`, border: "dotted 2px orange", color: `${this.props.color}` , outline: "none", backgroundColor: "none"}} value={this.state.elementTexte[3]} onChange={this.handleChange}  rows = "1" cols = "20"></textarea>       
            )
          }  
          <MenuCanvas grab={this.state.grab} handleClickUndo={this.handleClickUndo} handleClickGrag={this.handleClickGrag} handleZoomPlus={this.handleZoomPlus} handleZoomMinus={this.handleZoomMinus} zoom={this.state.zoom}/>
        </Fragment>
        );
    }

}
 
export default Canvas;