import React from 'react';

function Color(props) {
    
    const handleOnClick = (e) => {
        props.changeColor(e.target.style.color);
    }

    return ( 
                <div style={{position: "absolute", top: "7em",left: "5px", width: "5em", height: "25em", backgroundColor: "#3D3E3F", borderRadius: "0.2em" }}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop : "1em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{width: "3em", height: "3em", borderRadius: "0.2em", backgroundColor: props.color}}>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "1em"}}>
                        <div style={{cursor: "pointer", width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid red", color: "red" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12px", height: "12px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "red", position: "relative", top: "5.5px", left: "5.5px", color: "red" }}>
                            </div>
                        </div>
                        <div style={{ cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid orange", color: "orange" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12px", height: "12px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "orange", position: "relative", top: "5.5px", left: "5.5px", color: "orange" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer",width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid Chocolate", color: "Chocolate" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12px", height: "12px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "Chocolate", position: "relative", top: "5.5px", left: "5.5px", color: "Chocolate" }}>
                            </div>
                        </div>
                        <div style={{cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid olive", color: "olive" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12px", height: "12px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "olive", position: "relative", top: "5.5px", left: "5.5px", color: "olive" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer", width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid green", color: "green" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "green", position: "relative", top: "5.5px", left: "5.5px", color: "green" }}>
                            </div>
                        </div>
                        <div style={{ cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid DarkCyan", color: "DarkCyan" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "DarkCyan", position: "relative", top: "5.5px", left: "5.5px", color: "DarkCyan" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer",width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid blue", color: "blue" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "blue", position: "relative", top: "5.5px", left: "5.5px", color: "blue" }}>
                            </div>
                        </div>
                        <div style={{cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid DarkViolet", color: "DarkViolet" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "DarkViolet", position: "relative", top: "5.5px", left: "5.5px", color: "DarkViolet" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer", width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid pink", color: "pink" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "pink", position: "relative", top: "5.5px", left: "5.5px", color: "pink" }}>
                            </div>
                        </div>
                        <div style={{ cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid SaddleBrown", color: "SaddleBrown" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "SaddleBrown", position: "relative", top: "5.5px", left: "5.5px", color: "SaddleBrown" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid Salmon", color: "Salmon" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "Salmon", position: "relative", top: "5.5px", left: "5.5px", color: "Salmon" }}>
                            </div>
                        </div>
                        <div style={{cursor: "pointer",width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid SkyBlue", color: "SkyBlue" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "SkyBlue", position: "relative", top: "5.5px", left: "5.5px", color: "SkyBlue" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                        <div style={{cursor: "pointer", width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid Turquoise", color: "Turquoise" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "Turquoise", position: "relative", top: "5.5px", left: "5.5px", color: "Turquoise" }}>
                            </div>
                        </div>
                        <div style={{ cursor: "pointer",width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid Violet", color: "Violet" }} onClick={handleOnClick}>
                            <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "Violet", position: "relative", top: "5.5px", left: "5.5px", color: "Violet" }}>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop : "0.7em", marginLeft: "auto", marginRight: "auto"}}>
                    <div style={{cursor: "pointer",width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid SlateBlue", color: "SlateBlue" }} onClick={handleOnClick}>
                        <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "SlateBlue", position: "relative", top: "5.5px", left: "5.5px", color: "SlateBlue" }}>
                        </div>
                    </div>
                    <div style={{cursor: "pointer" ,width : "25px", height: "25px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "#3D3E3F", border: "1px solid black", color: "black" }} onClick={handleOnClick}>
                        <div style={{boxSizing: "border-box" ,width : "12.5px", height: "12.5px", borderRadius: "12.5px", fontSize: "1em", backgroundColor: "black", position: "relative", top: "5.5px", left: "5.5px", color: "black" }}>
                        </div>
                    </div>
                    </div>
                </div>
    );
}
 
export default Color;