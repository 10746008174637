import React, { Component } from 'react';
import { Modal, Button, Input, Message} from 'semantic-ui-react';

class Username extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            error: false,
            pseudo: ""
        }
        this.changerPseudo = this.changerPseudo.bind(this);
        this.close = this.close.bind(this);
    }
    
    close(){
        if(this.state.pseudo.length < 4){
            this.setState({
                error: true
            });
        }else{
            this.setState({
                error: false
            });
            this.props.changeUsername(this.state.pseudo);
        }
    }

    changerPseudo(e){
        this.setState({
            pseudo: e.target.value,
            error: false
        });
        localStorage.setItem('pseudo', e.target.value);
    }
    
    render(){
        return ( 
            <Modal
            open={this.props.open}
            closeOnEscape={false}
            >
            <Modal.Header>Pseudo</Modal.Header>
            <Modal.Content>
                <h4>Veuillez entrer un pseudo : </h4>
                <Input error={this.state.error} placeholder='Votre pseudo ...' value={this.state.pseudo} onChange={this.changerPseudo}/>
                {
                    this.state.error && (
                        <Message negative>
                            <p>Votre pseudo doit avoir plus de 4 caractères</p>
                        </Message>
                    )
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                onClick={this.close}
                positive
                labelPosition='right'
                icon='checkmark'
                content='Valider'
                />
            </Modal.Actions>
            </Modal>
        )
    }
}
 
export default Username;