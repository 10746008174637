import React, { Fragment, Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import Username from './PageCanvas/Username';

class Shared extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            open: false,
            Id: "",
        }
        this.changeUsername = this.changeUsername.bind(this);
    }

    componentDidMount(){
        this.setState({
            open: true
        });
    }

    handleOptions = (e) => {
 
    }

    changeUsername(value){
        this.setState({
            Id: value,
            open: false,
        });
        localStorage.setItem('userName', value);
        this.props.changeUsername(value);
    }

    render(){
        return ( 
            <Fragment>
                <Menu inverted icon vertical style={{position: "absolute", top: "29em",right: "5px" }}>
                    <Menu.Item
                        name='options'
                        onClick={this.handleOptions}
                        >
                        <Icon name='options' />
                    </Menu.Item>
                </Menu>
                <Username open={this.state.open} changeUsername={this.changeUsername}/>
            </Fragment>
        )
    }
}
 
export default Shared;