import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';


function Tools2(props) {
    
    const handleItemClick = (e, { name }) => {
        
        if(name === "expand arrows alternate" || name === "compress"){
            props.goFull();
        }

    }

    /* const handleClick = () => {
        props.savepng();
    } */

    return (
            <Menu inverted icon vertical style={{position: "absolute", top: "7em",right: "5px" }}>
                    {
                    props.isFull === false ? (
                                <Menu.Item
                                name='expand arrows alternate'
                                onClick={handleItemClick}
                                >
                                <Icon name='expand arrows alternate' />
                                </Menu.Item>
                    ) : (
                                <Menu.Item
                                name='compress'
                                onClick={handleItemClick}
                                >
                                <Icon name='compress' />
                                </Menu.Item>
                    )
                    }
                    { /** 
                    <Menu.Item
                        name='download'
                        onClick={handleClick}
                        >
                        <Icon name='download' />
                    </Menu.Item>*/
                    }
            </Menu>
         );
}
 
export default Tools2;