import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import uuid from 'react-uuid';

function Share(props){

    const handleShare = async (e) => {
        props.changerIdentifiant(uuid());
    }


    return ( 
        <Menu inverted icon vertical style={{position: "absolute", top: "29em",right: "5px" }}>
            <Menu.Item
                name='external share'
                onClick={handleShare}
                >
                <Icon name='external share' />
            </Menu.Item>
        </Menu>
    );
}
 
export default Share;